import React, { useState, useEffect } from 'react';
import { request, gql } from 'graphql-request';
import './merchModal.css';

const MerchModal = ({ show, onClose }) => {
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if ((password === 'keanureeves' || password === "Keanu Reeves") || password === "keanu reeves") {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password');
    }
  };

  useEffect(() => {
    if (show && isAuthenticated) {
      const scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';

      function loadScript() {
        const script = document.createElement('script');
        script.async = true;
        script.src = scriptURL;
        script.onload = ShopifyBuyInit;
        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
      }

      function ShopifyBuyInit() {
        const client = window.ShopifyBuy.buildClient({
          domain: 'vayda-store.myshopify.com',
          storefrontAccessToken: 'ad566c35f524c043be0df4198cb0b5c0',
        });
        window.ShopifyBuy.UI.onReady(client).then((ui) => {
          ui.createComponent('product', {
            id: '8160748601544',
            node: document.getElementById('product-component-1740685297625'),
            moneyFormat: '%24%7B%7Bamount%7D%7D',
            options: {
              "product": {
                "styles": {
                  "product": {
                    "@media (min-width: 601px)": {
                      "max-width": "100%",
                      "margin-left": "0",
                      "margin-bottom": "50px"
                    },
                    "text-align": "left"
                  },
                  "title": {
                    "font-family": "Roboto, sans-serif",
                    "font-size": "26px",
                    "color": "#000000"
                  },
                  "button": {
                    "font-family": "Roboto, sans-serif",
                    "font-weight": "bold",
                    ":hover": {
                      "background-color": "#50e600"
                    },
                    "background-color": "#59ff00",
                    ":focus": {
                      "background-color": "#50e600"
                    },
                    "border-radius": "0px",
                    "padding-left": "38px",
                    "padding-right": "38px"
                  },
                  "price": {
                    "font-family": "Roboto, sans-serif",
                    "font-size": "18px",
                    "color": "#000000"
                  },
                  "compareAt": {
                    "font-family": "Roboto, sans-serif",
                    "font-size": "15.299999999999999px",
                    "color": "#000000"
                  },
                  "unitPrice": {
                    "font-family": "Roboto, sans-serif",
                    "font-size": "15.299999999999999px",
                    "color": "#000000"
                  },
                  "description": {
                    "font-family": "Roboto, sans-serif",
                    "color": "#000000"
                  }
                },
                "layout": "horizontal",
                "contents": {
                  "img": false,
                  "imgWithCarousel": true,
                  "description": true
                },
                "width": "100%",
                "text": {
                  "button": "add to cart"
                },
                "googleFonts": [
                  "Roboto"
                ]
              },
              "productSet": {
                "styles": {
                  "products": {
                    "@media (min-width: 601px)": {
                      "margin-left": "-20px"
                    }
                  }
                }
              },
              "modalProduct": {
                "contents": {
                  "img": false,
                  "imgWithCarousel": true,
                  "button": false,
                  "buttonWithQuantity": true
                },
                "styles": {
                  "product": {
                    "@media (min-width: 601px)": {
                      "max-width": "100%",
                      "margin-left": "0px",
                      "margin-bottom": "0px"
                    }
                  },
                  "button": {
                    "font-family": "Roboto, sans-serif",
                    "font-weight": "bold",
                    ":hover": {
                      "background-color": "#50e600"
                    },
                    "background-color": "#59ff00",
                    ":focus": {
                      "background-color": "#50e600"
                    },
                    "border-radius": "0px",
                    "padding-left": "38px",
                    "padding-right": "38px"
                  },
                  "title": {
                    "font-family": "Helvetica Neue, sans-serif",
                    "font-weight": "bold",
                    "font-size": "26px",
                    "color": "#4c4c4c"
                  },
                  "price": {
                    "font-family": "Helvetica Neue, sans-serif",
                    "font-weight": "normal",
                    "font-size": "18px",
                    "color": "#4c4c4c"
                  },
                  "compareAt": {
                    "font-family": "Helvetica Neue, sans-serif",
                    "font-weight": "normal",
                    "font-size": "15.299999999999999px",
                    "color": "#4c4c4c"
                  },
                  "unitPrice": {
                    "font-family": "Helvetica Neue, sans-serif",
                    "font-weight": "normal",
                    "font-size": "15.299999999999999px",
                    "color": "#4c4c4c"
                  },
                  "description": {
                    "font-family": "Helvetica Neue, sans-serif",
                    "font-weight": "normal",
                    "font-size": "14px",
                    "color": "#4c4c4c"
                  }
                },
                "googleFonts": [
                  "Roboto"
                ],
                "text": {
                  "button": "Add to cart"
                }
              },
              "option": {},
              "cart": {
                "styles": {
                  "button": {
                    "font-family": "Roboto, sans-serif",
                    "font-weight": "bold",
                    ":hover": {
                      "background-color": "#50e600"
                    },
                    "background-color": "#59ff00",
                    ":focus": {
                      "background-color": "#50e600"
                    },
                    "border-radius": "0px"
                  }
                },
                "text": {
                  "total": "Subtotal",
                  "button": "Checkout"
                },
                "googleFonts": [
                  "Roboto"
                ]
              },
              "toggle": {
                "styles": {
                  "toggle": {
                    "font-family": "Roboto, sans-serif",
                    "font-weight": "bold",
                    "background-color": "#59ff00",
                    ":hover": {
                      "background-color": "#50e600"
                    },
                    ":focus": {
                      "background-color": "#50e600"
                    }
                  }
                },
                "googleFonts": [
                  "Roboto"
                ]
              }
            },
          });
        });
      }

      if (!window.ShopifyBuy) {
        loadScript();
      } else if (!window.ShopifyBuy.UI) {
        loadScript();
      } else {
        ShopifyBuyInit();
      }
    }
  }, [show, isAuthenticated]);

  if (!show) {
    return null;
  }

  if (!isAuthenticated) {
    return (
      <div className="vintage-terminal-overlay">
        <div className="vintage-terminal-content">
          <div className="modal-title-bar">
            <span className="modal-title">merch</span>
            <div>
              <button onClick={onClose} className="exit-button">-</button>
              <button onClick={onClose} className="exit-button">X</button>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '90%' }}>
            <form onSubmit={handlePasswordSubmit}>
              <input
                type="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Enter password"
              />
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="vintage-terminal-overlay">
      <div className="vintage-terminal-content">
        <div className="modal-title-bar">
          <span className="modal-title">merch</span>
          <div>
            <button onClick={onClose} className="exit-button">-</button>
            <button onClick={onClose} className="exit-button">X</button>
          </div>
        </div>
        <div className="lower-container" >
          <div id='product-component-1740685297625'></div>
        </div>

        {/* <div id="collection-component-1728847799346" style={{margin: '10px', overflow: 'scroll' }}></div> */}
      </div>
    </div>
  );
};

export default MerchModal;